import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { geocodeByLatLng } from "react-google-places-autocomplete";

const useNavigator = () => {
  // State
  const [getGeoLocation, setGetGeoLocation] = useState(false);
  const [isGeoAllowed, setIsGeoAllowed] = useState({
    permission: null,
    type: "",
  });
  const [modal, setModal] = useState({
    open: false,
    type: "",
  });

  //   State Toggler
  const toggleGetGeoLocation = useCallback(
    (bool) => {
      setGetGeoLocation(bool);
    },
    [getGeoLocation]
  );
  const toggleModal = useCallback(
    (data) => {
      setModal(data);
    },
    [modal]
  );

  /* Step 1 */
  useEffect(() => {
    if (getGeoLocation) {
      checkNavigator();
    }
  }, [getGeoLocation]);

  // Step 1:1
  // Check if browser supports navigator
  const checkNavigator = () => {
    if (
      navigator &&
      navigator.geolocation !== undefined &&
      navigator.permissions !== undefined
    ) {
      checkPermission();
    } else {
      toggleModal({
        open: true,
        type: "error",
        message: "Your browser doesnot support Geolocation",
      });
    }
  };

  // Step 2:1 Checking permission
  const checkPermission = () => {
    navigator.permissions.query({ name: "geolocation" }).then((data) => {
      let status = data?.state;
      if (status === "granted") {
        setIsGeoAllowed({ permission: true, type: "granted" });
        getUserLatLong();
      } else if (status === "denied") {
        setIsGeoAllowed({ permission: false, type: "denied" });
        toggleModal({
          open: true,
          type: isGeoAllowed?.type,
          message: "You have denied Geolocation, Please enable and try again.",
        });
      } else {
        setIsGeoAllowed({ permission: false, type: "notsupported" });
        toggleModal({
          open: true,
          type: isGeoAllowed?.type,
          message: "Your browser doesnot support Geolocation",
        });
      }
    });
  };

  // Step 3: Error Modal
  useEffect(() => {
    if (isGeoAllowed?.permission === false) {
      toggleModal({
        open: true,
        type: isGeoAllowed?.type,
        message: "You have denied Geolocation, Please enable and try again.",
      });
    }
  }, [isGeoAllowed]);

  // Step 4
  const getUserLatLong = () => {
    navigator.geolocation.getCurrentPosition(
      (response) => {
        // let leicester = {
        //   lat: 52.633331,
        //   lng: -1.133333,
        // };
        if (
          response &&
          response?.coords?.latitude &&
          response?.coords?.longitude
        ) {
          let positionObj = {
            lat: response?.coords?.latitude,
            lng: response?.coords?.longitude,
          };
          getAddress(positionObj);
        }
      },
      null,
      { enableHighAccuracy: true }
    );
  };

  const getAddress = async (data) => {
    // Geocode
    geocodeByLatLng(data)
      .then((results) => {
        toggleGetGeoLocation(false);
        if (results?.length > 0) {
          let res = results?.[0];
          if (res?.place_id) {
            handleSelection(res);
          }
        }
      })
      .catch((error) => {
        toggleModal({ open: true, type: "", message: "Something went wrong!" });
      });
  };

  const handleSelection = (data) => {
    let comps = data.address_components;
    let country = comps.find((ele) => ele.types.includes("country"));
    if (country?.long_name == "United Kingdom") {
      toggleModal({
        open: true,
        type: "success",
        message: data?.formatted_address,
        lat: data?.geometry?.location?.lat() ?? "",
        lng: data?.geometry?.location?.lng() ?? "",
      });
    } else {
      toggleModal({
        open: true,
        type: isGeoAllowed?.type,
        message: "We don't serve your area yet!",
      });
    }
  };

  const onCloseModal = () => {
    toggleModal({ open: false, type: "" });
    toggleGetGeoLocation(false);
  };

  const DetectUI = (
    <div>
      <h3
        onClick={() => toggleGetGeoLocation(true)}
        className="detect-location"
      >
        Detect Location
      </h3>
    </div>
  );

  return {
    DetectUI,
    modal,
    onCloseModal,
  };
};

export default useNavigator;
