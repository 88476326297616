import Dialog from "@mui/material/Dialog";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePlacesAutocomplete from "use-places-autocomplete";
import { addressTypeKey, filterTypeKey } from "../../../data";
import { locationAction } from "../../../stores/Slices/LocationSlice";
import useNavigator from "../../../utils/useNavigator";
import modalStyle from "../../../styles/ui/Dialog.module.scss";
import QueryString from "query-string";

const GoogleInput = dynamic(() => import("../../UI/Input/GoogleInput"), {
  ssr: true,
});
const Button = dynamic(() => import("../../UI/Buttons/Button"), {
  ssr: true,
});
const TextInput = dynamic(() => import("../../UI/Input/TextInput"), {
  ssr: true,
});

const SearchPostcode = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { DetectUI, modal, onCloseModal } = useNavigator();

  const { filters, form } = useSelector((state) => state.locationReducer);

  const [type, setType] = useState("");
  const [address, setaddress] = useState(null);

  const [paramType, setParamType] = useState(null);
  const [paramTerm, setParamTerm] = useState({});

  const [showErr, setShowErr] = useState(true);
  const [showTitleErr, setShowTitleErr] = useState(false);

  const updateType = useCallback(
    (value) => {
      setType(value);
    },
    [type]
  );

  const updateTitle = (titleValue) => {
    dispatch(
      locationAction.setFilters({
        ...filters,
        fetched: true,
        keys: {
          ...filters.keys,
          title: titleValue,
        },
      })
    );
  };

  const handleFilter = () => {
    let tempCareType = type ?? ``;
    let tempSearchTerm = address?.structured_formatting?.main_text;
    let tempAddressType = addressTypeKey[address?.address_type];
    let tempDisplayType = filterTypeKey[address?.address_type];

    if (address === null) {
      if (paramTerm?.value?.length > 0) {
        tempSearchTerm = paramTerm?.value;
      }
      if (paramTerm?.type?.length > 0) {
        tempAddressType = paramTerm?.type;
      }
    }

    if (paramType?.length > 0 && type?.length == 0) {
      tempCareType = paramType ?? ``;
    }

    `handleFilter`, filters;

    let link = `/care-result/search-${tempAddressType}/${tempSearchTerm}`;

    // All Care Type & Any Address Selected
    if (tempCareType?.length <= 0) {
      if (tempAddressType === "postcode") {
        link = `${link}?distance=10`;
      }
    } else {
      if (tempAddressType === "postcode") {
        link = `${link}/search/caretype/${tempCareType}?distance=10`;
      } else {
        link = `${link}/search/caretype/${tempCareType}`;
      }
    }

    dispatch(
      locationAction.setFilters({
        ...filters,
        fetched: true,
        keys: {
          ...filters.keys,
          careType: tempCareType,
          [tempDisplayType]: tempSearchTerm,
          residency_type: "",
          room_size: "",
          max: "",
          min: "",
        },
      })
    );
    router.push(`${link}`);
  };

  const handleSearch = () => {
    if (props?.isOriginHome) {
      if (type || address) {
        if (address === null) {
          router.push(`/search?page=1&careType=${type}`);
        } else {
          handleFilter();
        }
        setShowErr(false);
      } else {
        setShowErr(true);
      }
    } else {
      if (address === null && paramTerm?.value === undefined) {
        dispatch(
          locationAction.setFilters({
            ...filters,
            fetched: false,
            keys: {
              ...filters.keys,
              careType: type,
              residency_type: "",
              room_size: "",
              max: "",
              min: "",
            },
          })
        );
      } else {
        handleFilter();
      }
    }
  };

  const handleTitleSearch = () => {
    if (filters?.keys?.title?.length <= 3) {
      setShowTitleErr(true);
    } else {
      setShowTitleErr(false);
      router.push(`/search?page=1&title=${filters?.keys?.title}`);
    }
  };

  const {
    ready,
    suggestions: { data },
    setValue,
    value,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["UK"] },
      debounce: 300,
      types: ["(regions)"],
    },
  });

  useEffect(() => {
    navigator?.geolocation?.getCurrentPosition(() => {});
    setShowErr(false);
  }, []);

  useEffect(() => {
    if (filters?.keys[filterTypeKey[props?.type]]) {
      setParamTerm({
        value: filters?.keys[filterTypeKey[props?.type]],
        type: addressTypeKey[props?.type],
      });
      setValue(filters?.keys[filterTypeKey[props?.type]], false);
    } else {
      setParamTerm({});
    }

    // Param Type
    if (filters?.keys?.careType?.length > 0) {
      setParamType(filters?.keys?.careType);
      setType(filters?.keys?.careType);
    } else {
      setParamType("");
      setType("");
    }
  }, [filters]);

  const handleLocation = () => {
    dispatch(
      locationAction.setFilters({
        ...filters,
        fetched: false,
        keys: { ...filters.keys, lat: modal?.lat, long: modal?.lng },
      })
    );
    onCloseModal();
  };

  useEffect(() => {
    if (form === "location") {
      setShowTitleErr(false);
    } else {
      setShowErr(false);
    }
  }, [form]);

  return (
    <>
      <div className={props.style.queryInput}>
        <div className={props.style.formWrap}>
          {form === "location" ? (
            <>
              <div className={`${props.style.type} ${props.style.formCard}`}>
                <select
                  name="filter-care-type"
                  id="filter-care-type"
                  value={type}
                  onChange={(e) => {
                    updateType(
                      e?.target?.value === "all" ? "" : e?.target?.value
                    );
                    setParamType("");
                    setShowErr(false);
                  }}
                >
                  <option value="all">All Care Types</option>
                  {props.careTypeList.length > 0 &&
                    props.careTypeList.map((option, key) => (
                      <option value={option?.care_name} key={key}>
                        {option?.care_name ?? ""}
                      </option>
                    ))}
                </select>
              </div>
              <div className={`${props.style.google} ${props.style.formCard}`}>
                {ready && (
                  <GoogleInput
                    list={data}
                    setValue={setValue}
                    customValue={value}
                    setaddress={setaddress}
                    setShowErr={setShowErr}
                  />
                )}
              </div>
            </>
          ) : (
            <div className={`${props.style.title} ${props.style.formCard}`}>
              <TextInput
                type="text"
                label="Location title"
                placeholder="Enter"
                disabled={false}
                value={filters?.keys?.title}
                onChange={(e) => {
                  updateTitle(e?.target?.value ?? "");
                  setShowTitleErr(false);
                }}
                hideLabel={true}
              />
            </div>
          )}

          {props.hideDetect !== true && (
            <div className={`${props.style.detect} ${props.style.formCard}`}>
              {DetectUI}
            </div>
          )}
          {showErr && (
            <p className={`${props.style.err}`}>
              Atleast one select is required!
            </p>
          )}
          {showTitleErr && (
            <p className={`${props.style.err}`}>
              The search title length should be atleast 3!
            </p>
          )}
        </div>
        <div className={props.style.formAction}>
          <Button
            label="Search"
            variant="primary"
            fullWidth={true}
            onClick={() =>
              form === "location"
                ? handleSearch()
                : form === "title"
                ? handleTitleSearch()
                : null
            }
            size="xl"
          />
        </div>
      </div>
      {modal.open && (
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={modal?.open}
          onClose={() => onCloseModal()}
        >
          <div className={modalStyle.header}>
            <h3 className={modalStyle.title}>Detect Location</h3>
          </div>
          <div className={modalStyle.content}>
            <h3>Location</h3>
            <p className="detect-desc">{modal?.message}</p>
          </div>
          <div className={modalStyle.actions}>
            <Button
              variant="primary"
              label={modal?.type === "success" ? `Search` : "Close"}
              onClick={() =>
                modal?.type === "success" ? handleLocation() : onCloseModal()
              }
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default SearchPostcode;
